import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import BenefitsSection from 'components/organisms/BenefitsSection';
import CtaSection from 'components/organisms/CtaSection';
import FeaturesSection from 'components/organisms/FeaturesSection';
import Hero from 'components/organisms/Hero';
import MasonrySection from 'components/organisms/MasonrySection';
import ProductTilesSection from 'components/organisms/ProductTilesSection';
import SupportSection from 'components/organisms/SupportSection';
import TaglineSection from 'components/organisms/TaglineSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import getImageData from 'helpers/nf-image-data';

const heroImageData = getImageData('/images/fluid/scan-data-hero.jpg');

const ScanDataContent = ({
  hero,
  productTiles,
  tagline,
  benefits,
  masonry,
  features,
  support,
  testimonial,
  cta,
}) => {
  return (
    <>
      <Hero
        breadcrumb="Scan Data"
        heading={hero?.title}
        content={hero?.description}
        imageData={heroImageData}
        reverse={true}
        mask={true}
        button={{
          to: hero?.primaryCta?.url,
          label: hero?.primaryCta?.label,
        }}
        calloutSceneWidths="w-full md:w-7/12 max-w-2xl mx-auto md:max-w-full"
        cards={[
          {
            heading: '$1.43 Reimbursed',
            content: 'Scan Data',
            color: 'violet',
            y: '61%',
            x: '45%',
            mobileY: '63%',
            mobileX: '27%',
          },
        ]}
      />

      <ProductTilesSection
        title={productTiles?.title}
        body={productTiles?.body}
      />

      <TaglineSection title={tagline?.title} cta={tagline?.cta} />

      <BenefitsSection>
        {benefits?.benefits?.map(({ title, body, image, imageSide }, index) => (
          <Benefit
            key={index}
            title={title}
            body={body}
            image={image}
            imageSide={imageSide}
          />
        ))}
      </BenefitsSection>

      <MasonrySection title={masonry?.title} body={masonry?.body} />

      <FeaturesSection title={features?.title} body={features?.body}>
        {features?.features?.map(({ title, body, image }, index) => (
          <Feature key={index} title={title} body={body} image={image} />
        ))}
      </FeaturesSection>

      <SupportSection
        title={support?.title}
        body={support?.body}
        imageUrl={support?.image}
      />

      <TestimonialSection
        quote={testimonial?.quote}
        ctaLabel="Read customer story"
      />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const ScanDataPreview = ({ entry }) => {
  return <ScanDataContent {...entry.get('data').toJS()} />;
};

const ScanDataPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <ScanDataContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ScanDataPageQuery {
    markdownRemark(
      fileAbsolutePath: { regex: "/convenience-retail/scan-data/" }
    ) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        productTiles {
          title
          body
        }
        tagline {
          title
          cta {
            label
            url
          }
        }
        benefits {
          title
          benefits {
            title
            body
            image
            imageSide
          }
        }
        masonry {
          title
          body
        }
        features {
          title
          body
          features {
            title
            body
            image
          }
        }
        support {
          title
          body
          image
        }
        testimonial {
          quote
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default ScanDataPage;
