import React from 'react';
import LottieAnimation from 'components/atoms/LottieAnimation';

interface ProductTilesSectionProps {
  title: string;
  body: string;
}

const ProductTilesSection: React.FC<ProductTilesSectionProps> = ({
  title,
  body,
}) => {
  return (
    <div className="container max-w-screen-xl mx-auto mt-20 -mb-20 md:my-32">
      <div className="flex flex-col-reverse items-center lg:flex-row text-dark-grey">
        <div className="w-full md:w-8/12 lg:w-6/12 bg-tile">
          <div className="relative" style={{ paddingBottom: '92.5%' }}>
            <div className="absolute inset-0">
              <LottieAnimation lazyData="scan-data" play={true} />
            </div>
          </div>
        </div>
        <div className="px-6 mb-16 text-center md:w-8/12 lg:text-left lg:w-6/12 lg:mb-0 md:px-0 lg:px-16">
          <div className="lg:ml-4 xl:ml-8 lg:mr-11">
            <h2 className="mb-3.5 u-h2">{title}</h2>
            <p className="text-lg lg:text-xl lg:mr-9">{body}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTilesSection;
