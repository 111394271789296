import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';

interface BenefitsSectionProps {
  title?: string;
  body?: string;
  cta?: { to: string; label: string };
  constrainTitle?: boolean;
  className?: string;
}

const BenefitsSection: React.FC<BenefitsSectionProps> = ({
  title,
  body,
  cta,
  constrainTitle,
  className,
  children,
}) => {
  return (
    <div
      className={`u-section u-container text-center md:text-left ${className}`}
    >
      {title && (
        <h2
          className={`text-center px-6 md:mx-auto ${
            body ? 'mb-3' : 'mb-16 lg:mb-32'
          } u-h2 ${
            constrainTitle ? 'mx-auto max-w-3xl' : ''
          } leading-tighter tracking-tightest`}
        >
          {html2react(title)}
        </h2>
      )}
      {body && (
        <p
          className={`text-center text-xl text-paragraph mb-8 md:mb-8 ${
            constrainTitle ? 'mx-auto w-article max-w-full px-6 md:px-14' : ''
          }`}
        >
          {body}
        </p>
      )}
      {cta && (
        <div className="text-center mb-16 lg:mb-32">
          <Link to={cta.to} className="u-cta-link">
            {cta.label}
          </Link>
        </div>
      )}
      {children}
    </div>
  );
};

export default BenefitsSection;
