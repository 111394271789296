import { Link } from 'gatsby';
import React from 'react';
import MasonryTile from 'components/molecules/MasonryTile';

interface MasonrySectionProps {
  title: string;
  body: string;
  ctaCopy?: string;
  ctaLabel?: string;
  ctaUrl?: string;
}

const MasonrySection: React.FC<MasonrySectionProps> = ({
  title,
  body,
  ctaCopy,
  ctaLabel,
  ctaUrl,
}) => {
  return (
    <div className="container max-w-screen-xl mx-auto my-20 md:my-32">
      <div className="grid grid-cols-2 gap-4 lg:grid-rows-6 lg:grid-cols-4">
        <div className="order-2 col-span-1 row-span-1 h-64 md:h-auto md:row-span-3 md:col-span-1">
          <MasonryTile color="#F8962F">
            <div className="font-bold leading-tight text-100">1</div>
            <div className="text-2xl font-semibold tracking-tighter">
              One day set up
            </div>
          </MasonryTile>
        </div>

        <div className="order-1 col-span-2 row-span-4 lg:order-2">
          <MasonryTile color="#082534">
            <div className="px-12">
              <h2 className="u-h2 mb-3.5 ">{title}</h2>
              <p className="text-xl tracking-tight">{body}</p>
            </div>
          </MasonryTile>
        </div>

        <div className="order-6 col-span-2 row-span-6 lg:col-span-1 lg:order-3">
          <MasonryTile color="#4CC3D2">
            <div className="flex flex-col items-center justify-center flex-1 py-16 md:py-4 lg:py-16 md:flex-row lg:flex-col mx-9">
              <div className="flex flex-col items-center">
                <img
                  className="w-auto h-32 mb-6"
                  src="/images/pos-device.svg"
                  alt="Clover device"
                />
                <div className="mx-4 text-2xl font-semibold leading-tight tracking-tighter">
                  A Clover device or MNSP
                </div>
              </div>
              <div className="flex items-center justify-between w-full md:h-40 lg:h-auto md:flex-col lg:flex-row my-9">
                <div className="flex-1 flex-grow h-px bg-white md:h-auto md:w-px lg:w-auto lg:h-px opacity-30" />
                <div className="flex-grow-0 flex-shrink-0 mx-4 text-xl italic font-semibold text-center md:mx-0 lg:mx-4 md:my-4 lg:my-0">
                  or
                </div>
                <div className="flex-1 flex-grow h-px bg-white md:h-auto md:w-px lg:w-auto lg:h-px opacity-30" />
              </div>
              <div className="flex flex-col items-center">
                <img
                  className="w-auto h-16 mb-14"
                  src="/images/windows.svg"
                  alt="Windows"
                />
                <div className="mx-4 text-2xl font-semibold leading-tight tracking-tighter">
                  Any Windows 7, 8, or 10 device
                </div>
              </div>
            </div>
          </MasonryTile>
        </div>

        <div className="order-3 col-span-1 row-span-1 md:row-span-3 h-64 md:h-auto lg:order-4 md:col-span-1">
          <MasonryTile color="#4CC3D2">
            <img
              className="h-auto mb-8 w-28"
              src="/images/no-long-term-commitment.svg"
              alt="No long-term commitments"
            />
            <div className="mx-16 text-2xl font-semibold tracking-tighter">
              No long-term commitments
            </div>
          </MasonryTile>
        </div>

        <div className="order-4 row-span-1 md:row-span-2 h-64 md:h-auto lg:order-5">
          <MasonryTile color="#F8962F">
            <img
              className="w-12 h-auto mb-5"
              src="/images/remote-setup.svg"
              alt="Remove setup"
            />
            <div className="mx-4 text-2xl font-semibold tracking-tighter">
              Remote setup
            </div>
          </MasonryTile>
        </div>

        <div className="order-5 row-span-1 md:row-span-2 h-64 md:h-auto lg:order-5">
          <MasonryTile color="#4CC3D2">
            <img
              className="h-auto mb-4 w-11"
              src="/images/internet-connection.svg"
              alt="An internet connection"
            />
            <div className="mx-4 text-2xl font-semibold leading-tight tracking-tighter">
              An internet connection
            </div>
          </MasonryTile>
        </div>
      </div>

      {/*
      <div className="text-center mt-11 tex-lg lg:text-xl">
        {ctaCopy}{' '}
        <Link
          to={ctaUrl}
          className="text-aqua border-b border-aqua hover:border-dark-grey hover:text-dark-grey transition-colors"
        >
          {ctaLabel}
        </Link>
      </div>
      */}
    </div>
  );
};

export default MasonrySection;
