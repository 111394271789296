import React from 'react';

interface MasonryTileProps {
  color: string;
}

const MasonryTile: React.FC<MasonryTileProps> = ({ color, children }) => {
  return (
    <div
      className="flex flex-col items-center justify-center h-full py-16 text-center text-white lg:py-9"
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
};

export default MasonryTile;
