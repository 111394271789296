import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';

interface TaglineSectionProps {
  title: string;
  cta: {
    label: string;
    url: string;
  };
}

const TaglineSection: React.FC<TaglineSectionProps> = ({ title, cta }) => {
  return (
    <div className="my-20 text-center bg-dark-grey md:my-32">
      <div className="container max-w-screen-xl px-10 py-20 md:py-32 mx-auto lg:py-40 md:px-16 lg:px-24 xl:px-32">
        <h2 className="text-4xl font-semibold text-white md:text-6xl mb-11">
          {html2react(title)}
        </h2>

        <Link className="u-cta-link u-cta-link--white" to={cta.url}>
          {cta.label}
        </Link>
      </div>
    </div>
  );
};

export default TaglineSection;
