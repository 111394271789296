import React from 'react';

interface SupportSectionProps {
  title: string;
  body: string;
  imageUrl: string;
}

const SupportSection: React.FC<SupportSectionProps> = ({
  title,
  body,
  imageUrl,
}) => {
  return (
    <div className="container flex flex-col-reverse justify-between max-w-screen-xl mx-auto my-20 lg:flex-row bg-dark-grey md:my-32">
      <div className="flex items-center flex-grow px-6 py-16 text-white md:px-24 md:py-24 lg:py-0 lg:w-7/12">
        <div>
          <h2 className="mb-4 u-h2">{title}</h2>
          <p className="text-lg md:text-xl mr-2.25 tracking-tighter mb-6">{body}</p>
          <a href="https://support.skupos.com" className="u-cta-link u-cta-link--white" target="_blank">Visit the Help Center</a>
        </div>
      </div>
      <div
        className="flex-shrink-0 bg-center bg-cover h-96 lg:h-banner lg:w-5/12"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
    </div>
  );
};

export default SupportSection;
